import { type Tag } from '../../../src/types';
import { TagBanner } from '../Tag/TagBanner';

export function GamePackTagBanner(props: {
  tag: Tag;
  breadcrumb?: React.ReactNode;
}) {
  const { tag, breadcrumb } = props;

  return (
    <div className='flex-none relative w-full h-80'>
      <div className='absolute w-full h-full'>
        <TagBanner tag={tag} />
      </div>

      <div
        className='absolute w-full h-full bg-modal'
        style={{
          clipPath: 'ellipse(60% 10% at 50% 100%)',
        }}
      ></div>

      <div className='absolute top-10 left-20'>{breadcrumb}</div>
    </div>
  );
}
