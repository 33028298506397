import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { StarIcon } from '../../../src/components/icons/StarIcon';
import { TagIcon } from '../../../src/components/Tagging/TagIcon';
import { type Tag } from '../../../src/types';
import { TagSupportingMedia } from './TagSupportingMedia';
import { getTagStyles } from './utils';

export function TagBanner(props: { tag: Tag }) {
  const { tag } = props;

  const styles = getTagStyles(tag);

  return (
    <div
      className={`relative w-full h-full flex justify-center items-center gap-10`}
      style={{
        background: styles.background,
      }}
    >
      <div className='max-w-120 flex flex-col gap-2.5'>
        <div className='flex items-center gap-4'>
          <TagIcon tag={tag} className='w-9 h-9 object-contain' />
          <div
            className='font-Montserrat text-3.5xl font-black'
            style={{
              color: styles.titleTextColor,
            }}
          >
            {tag.name}
          </div>
        </div>

        <div className='ml-13 text-sms tracking-wide'>{tag.description}</div>

        <div className='ml-13 flex flex-col gap-2'>
          <div className='flex items-center gap-1'>
            <StarIcon className='w-3.5 h-3.5 text-tertiary' />
            <div className='text-sms font-bold'>
              <span className='text-tertiary'>9.2</span>
              <span> rated by </span>
              <span className='text-tertiary'>90K+</span>
              <span> customers in 100+ countries</span>
            </div>
          </div>
          <img
            src={getStaticAssetPath('images/tag-banner-companies.png')}
            className='object-contain w-78 h-4'
            alt=''
          />
        </div>
      </div>

      <TagSupportingMedia tag={tag} className='flex-none h-full object-cover' />
    </div>
  );
}
